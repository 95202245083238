import React from "react";

import Layout from "../../components/legal/layout";
import Head from "../../components/head";

import styles from "../../styles/legal/legal.module.scss";

const TermsPage = () => (
	<Layout location="terms">
		<div className={styles.content}>
			<Head title="Terms & Conditions" />
			
			<h1 className={styles.heading}>Terms & Conditions</h1>
			<div>
				<p>By using this website, you are deemed to have accepted these Terms and Conditions of use.</p>

				<p>Critical TechWorks has made every possible effort to ensure the accuracy of all the information contained on this website. Notwithstanding our commitment to provide accurate and updated information, this website should not be considered a strict reflection of everything offered by Critical TechWorks.</p>

				<p>All copyright, trademarks and all other intellectual property rights, including databases on the website and its content, are the property of Critical TechWorks or authorised for its use. It is prohibited to copy, reproduce, re-edit, publish, broadcast or transmit any text, image, graphic, logotype, icon or software code that appears on the website for any public or private purpose, without the prior written authorisation of Critical TechWorks.</p>

				<p>It is forbidden to adapt, modify or recreate any of the information or materials that appear on this website or to utilise them for any purpose that is not strictly personal and not-for-profit. The user also accepts to access and use the website only for lawful purposes.</p>

				<p>Due to the inherent nature of the internet, errors, interruptions and delays may occur in the service at any time. Accordingly, this website is provided without any warranties of any kind and Critical TechWorks does not accept any liability arising from any interruption to the website whatsoever.</p>

				<p>Nothing in these Terms of Use shall restrict or exclude any liability that Critical TechWorks has to any party that cannot be excluded by law.</p>
			</div>
		</div>
	</Layout>
);

export default TermsPage;
